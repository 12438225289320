/* eslint-disable @typescript-eslint/no-invalid-void-type */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import type { TagDescription } from '@reduxjs/toolkit/query'

import type {
  FacebookPostCreateRequest,
  FacebookPostUpdateRequest,
  InstagramPostCreateRequest,
  InstagramPostUpdateRequest,
  PagePostResponse,
  PostProduct,
  PostResponse,
} from '../../types'
import api from '../api'

// remove required title from Product approved back
type PatchProduct<T> = T extends { product?: PostProduct }
  ? Omit<T, 'product'> & { product?: Pick<PostProduct, 'id'> }
  : T

interface Draftable<T> {
  draft?: boolean
  body: T
}

const postsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    posts: builder.query<PagePostResponse, string>({
      query: (params: string) => ({
        method: 'GET',
        url: `api/posts${params ? `?${params}` : ''}`,
      }),
      providesTags: (result: PagePostResponse | undefined) =>
        Array.isArray(result?.content)
          ? [
              ...((result as PagePostResponse).content as PostResponse[]).reduce<
                Array<TagDescription<'Post-item'>>
              >((cache, { id }) => {
                if (id !== undefined) {
                  cache.push({ type: 'Post-item' as const, id })
                }
                return cache
              }, []),
            ]
          : [{ type: 'Post-item', id: 'LIST' }],
    }),
    post: builder.query<PostResponse, string>({
      query: (postId: string) => `api/posts/${postId}`,
      providesTags: (result, error, postId) => [{ type: 'Post-item', id: postId }],
    }),
    createInstagramPost: builder.mutation<
      PostResponse,
      Draftable<PatchProduct<InstagramPostCreateRequest>>
    >({
      query: ({ draft, body }) => ({
        url: `api/posts/instagram`,
        method: 'POST',
        params: { draft },
        body,
      }),
      invalidatesTags: ['Post-item'],
    }),
    createFacebookPost: builder.mutation<
      PostResponse,
      Draftable<PatchProduct<FacebookPostCreateRequest>>
    >({
      query: ({ draft, body }) => ({
        url: `api/posts/facebook`,
        method: 'POST',
        params: { draft },
        body,
      }),
      invalidatesTags: ['Post-item'],
    }),
    editFacebookPost: builder.mutation<
      PostResponse,
      Draftable<PatchProduct<FacebookPostUpdateRequest>>
    >({
      query: ({ draft, body }) => ({
        url: `api/posts/facebook`,
        method: 'PUT',
        params: { draft },
        body,
      }),
      invalidatesTags: ['Post-item'],
    }),
    editInstagramPost: builder.mutation<
      PostResponse,
      Draftable<PatchProduct<InstagramPostUpdateRequest>>
    >({
      query: ({ draft, body }) => ({
        url: 'api/posts/instagram',
        method: 'PUT',
        params: { draft },
        body,
      }),
      invalidatesTags: ['Post-item'],
    }),
    removePosts: builder.mutation<void, string | string[]>({
      query: (postIdOrIds) =>
        Array.isArray(postIdOrIds)
          ? {
              url: 'api/posts',
              method: 'DELETE',
              body: { ids: postIdOrIds },
            }
          : {
              url: `api/posts/${postIdOrIds}`,
              method: 'DELETE',
            },
      invalidatesTags: (result, error, postIdOrIds) => [
        ...(Array.isArray(postIdOrIds)
          ? postIdOrIds.map((id) => ({ type: 'Post-item' as const, id }))
          : [{ type: 'Post-item' as const, id: postIdOrIds }]),
        { type: 'Post-statistics' as const },
      ],
    }),
    postsStatistics: builder.query<Record<string, number>, void>({
      keepUnusedDataFor: 0,
      query: () => ({
        method: 'GET',
        url: 'api/posts/statistics',
      }),
      providesTags: ['Post-statistics'],
    }),
    movePostToDraft: builder.mutation({
      query: (postId: string) => ({
        method: 'PUT',
        url: `api/posts/${postId}/draft`,
      }),
      invalidatesTags: ['Post-item'],
    }),
    sendPost: builder.mutation({
      query: ({ body }) => ({
        method: 'POST',
        url: 'api/posts/send',
        body,
      }),
      invalidatesTags: ['Post-item'],
    }),
  }),
})

export const {
  usePostsQuery,
  useCreateInstagramPostMutation,
  useLazyPostQuery,
  usePostQuery,
  useEditInstagramPostMutation,
  useEditFacebookPostMutation,
  useCreateFacebookPostMutation,
  useRemovePostsMutation,
  usePostsStatisticsQuery,
  useLazyPostsStatisticsQuery,
  useLazyPostsQuery,
  useMovePostToDraftMutation,
  useSendPostMutation,
} = postsApi

export default postsApi
