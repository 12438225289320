import { Box, Card } from '@mui/joy'
import { type FC, memo } from 'react'

import { type FormWithPreviewProps } from './BoxWithBar.types'

/**
 * BoxWithBar is a just view of the typical form
 */
const BoxWithBar: FC<FormWithPreviewProps> = ({ children, bar = null }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 1,
        mx: 2,
      }}
    >
      <Box width="100%" flex={1}>
        {children}
      </Box>

      {bar !== null && (
        <Card
          variant="outlined-blur"
          sx={{
            zIndex: 2,
            p: 1,
            mb: 2,
            mt: 'auto',
            position: 'sticky',
            bottom: 16,
            right: 0,
            left: 0,
            width: 1165,
          }}
        >
          {bar}
        </Card>
      )}
    </Box>
  )
}

export default memo(BoxWithBar)
