import { type PostResponse } from '../../types'

export const getPostStatus = (post?: PostResponse, postId?: string): string => {
  const id = postId ?? post?.id

  if (!id) return 'UNKNOWN'

  if (id === 'new') return 'NEW'

  if (!post) return 'UNKNOWN'

  return post.status ?? 'UNKNOWN'
}
