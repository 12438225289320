import styled from '@emotion/styled'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import type { ChangeEvent, FormEvent, ReactElement } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const RegisterForm = (): ReactElement => {
  const [formValue, setFormValue] = useState({ email: '' })

  const navigate = useNavigate()

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setFormValue((previousFormValue) => ({ ...previousFormValue, email: event.target.value }))
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    navigate('/posts?status=SCHEDULED')
    event.preventDefault()
  }

  return (
    <StyledForm action="/posts" onSubmit={handleSubmit}>
      <Avatar sx={{ marginBottom: 2 }}>A</Avatar>
      <Typography variant="h6" sx={{ marginBottom: 1 }}>
        Welcome to AdManager
      </Typography>
      <Typography variant="caption" fontWeight={100}>
        Set up your account within few steps
      </Typography>
      <TextField
        id="email"
        label="email"
        sx={{ marginY: 1, width: '100%' }}
        value={formValue.email}
        onChange={handleChange}
      ></TextField>
      <Button fullWidth variant="contained" type="submit">
        Continue with email
      </Button>
    </StyledForm>
  )
}

export default RegisterForm
