import { yupResolver } from '@hookform/resolvers/yup'
import { type FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import BoxWithBar from '../../../../../../components/views/BoxWithBar/BoxWithBar'
import FormCard from '../../../../../../components/views/FormCard/FormCard'
import PreviewBox from '../../../../../../components/views/PreviewBox/PreviewBox'
import { TransparentForm } from '../../../../../../components/views/TransparentForm/TransparentForm'
import PostsFormBar from '../PostsFormBar/PostsFormBar'
import PostForm from './components/PostForm/PostForm'
import { postsFormSchema } from './PostsForm.schema'
import { type PostsFormProps, type PostsFormValues } from './PostsForm.types'

const PostsForm: FC<PostsFormProps> = ({
  defaultValues,
  socialAccounts,
  renderPostFormPreview,
  onSubmit,
}) => {
  const form = useForm<PostsFormValues>({
    defaultValues,
    resolver: yupResolver<PostsFormValues>(postsFormSchema),
  })

  const postForms = form.watch('posts')

  return (
    <TransparentForm onSubmit={form.handleSubmit(onSubmit)}>
      <FormProvider {...form}>
        <BoxWithBar bar={<PostsFormBar isSubmitting={form.formState.isSubmitting} />}>
          {!form.formState.isLoading &&
            postForms.map((postForm, index) => (
              <PreviewBox
                key={postForm.socialAccountId}
                preview={
                  <>
                    {renderPostFormPreview !== undefined
                      ? renderPostFormPreview(postForm)
                      : 'Post preview unavailable'}
                  </>
                }
              >
                <FormCard>
                  <PostForm
                    key={`${index}`}
                    socialAccounts={socialAccounts}
                    control={form.control}
                    index={index}
                  />
                </FormCard>
              </PreviewBox>
            ))}
        </BoxWithBar>
      </FormProvider>
    </TransparentForm>
  )
}

export default PostsForm
