import Avatar, { type AvatarProps } from '@mui/joy/Avatar'
import type { FC } from 'react'

import facebook from '../../../assets/img/facebook.svg'
import instagram from '../../../assets/img/instagram.svg'
import pinterest from '../../../assets/img/pinterest.svg'
import tiktok from '../../../assets/img/tiktok.svg'
import { type SocialType } from '../../../types'

const getSrc = (socialName: SocialType): string => {
  switch (socialName) {
    case 'FACEBOOK':
      return facebook
    case 'PINTEREST':
      return pinterest
    case 'TIKTOK':
      return tiktok
    case 'INSTAGRAM':
    default:
      return instagram
  }
}

interface SocialAccountAvatarProps extends AvatarProps {
  socialName: SocialType
}

const SocialAvatar: FC<SocialAccountAvatarProps> = ({ socialName, ...props }) => (
  <Avatar src={getSrc(socialName)} {...props} />
)

export default SocialAvatar
