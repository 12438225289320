/* eslint-disable react/prop-types */
import { useState } from 'react'
import Box from '@mui/material/Box'
import { SocialType } from '../../types'
import type { Post } from '../../types'
import PostPreviewHeader from './components/PostPreviewHeader'
import InstagramComponent from './components/InstagramComponent'
import FacebookComponent from './components/FacebookComponent'

export interface PostPreviewProps {
  post: Post
}

const PostPreview: React.FC<PostPreviewProps> = (props) => {
  const [isVideoMuted, setIsVideoMuted] = useState(props.post.isMuted)

  const onVideoMute = (muted: boolean | undefined): void => {
    setIsVideoMuted(muted)
  }
  const containsVideo = props.post.media?.some((media) => media.url && media.url.endsWith('.mp4'))

  let SocialTypeBasedComponent
  switch (props.post.socialType) {
    case SocialType.INSTAGRAM:
      SocialTypeBasedComponent = InstagramComponent
      break
    case SocialType.FACEBOOK:
      SocialTypeBasedComponent = FacebookComponent
      break
  }

  return (
        <Box sx={{ width: '100%', maxWidth: '470px', wordBreak: 'break-all', userSelect: 'none' }}>
             <PostPreviewHeader
                socialType={props.post.socialType}
                postType={props.post.postType}
                isVideoMuted={isVideoMuted}
                onVideoMute={onVideoMute}
                showMuteButton={containsVideo}
             />
             {SocialTypeBasedComponent !== undefined && (
                 <Box sx={{ marginTop: '20px' }}>
                   <SocialTypeBasedComponent {...props} isVideoMuted={isVideoMuted} onVideoMute={onVideoMute} />
                 </Box>
             )}
        </Box>
  )
}

export default PostPreview
