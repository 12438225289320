import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { type RootState, useAppDispatch } from '../store'
import postsApi, { usePostsStatisticsQuery } from '../store/endpoints/posts'
import { untrackId } from '../store/posts/actions'
import { type PostResponse } from '../types'
import { sendPostNotification } from '../utils/sendPostNotification'

const usePublishingPosts = (): PostResponse[] => {
  const trackedIds: string[] = useSelector((state: RootState) => state.posts.trackedIds)
  const initPosts = trackedIds.map<PostResponse>((id) => ({ id, status: 'PUBLISHING' }))
  const [publishingPosts, setPublishingPosts] = useState<PostResponse[]>(initPosts)
  const { data: postsStatistics } = usePostsStatisticsQuery()
  const dispatch = useAppDispatch()
  const lock = useRef<boolean>(false)

  useEffect(() => {
    const publishing = postsStatistics?.PUBLISHING ?? 0

    if (publishing > 0 && !lock.current) {
      lock.current = true
      const eventSource = new EventSource('/api/posts/sse/subscribe', { withCredentials: true })

      eventSource.addEventListener('post-update', (event) => {
        const newPublishingPost = JSON.parse(event.data) as Partial<PostResponse>

        if ('status' in newPublishingPost && 'id' in newPublishingPost) {
          const postId = newPublishingPost.id
          if (postId) {
            dispatch(untrackId(postId))
          }
          sendPostNotification(dispatch, newPublishingPost)
          setPublishingPosts((prevPosts: PostResponse[]): PostResponse[] => {
            const result = prevPosts.filter((post: PostResponse) => post.id !== postId)
            if (newPublishingPost.status === 'PUBLISHING') {
              return [...result, newPublishingPost as PostResponse]
            }
            return result
          })

          if (newPublishingPost.status !== 'PUBLISHING') {
            dispatch(postsApi.util.invalidateTags(['Post-item', 'Post-statistics']))
          }
        }
      })

      const unsubscribe = (): void => {
        lock.current = false
        eventSource.close()
      }

      eventSource.onerror = () => {
        unsubscribe()
      }

      return () => {
        unsubscribe()
      }
    }
  }, [postsStatistics?.PUBLISHING, lock])

  return publishingPosts
}

export default usePublishingPosts
