import { type FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import PostsFormSkeleton from '../../components/skeletons/PostsFormSkeleton/PostsFormSkeleton'
import RootBox from '../../components/views/RootBox/RootBox'
import useSocialAccounts from '../../hooks/useSocialAccounts'
import { usePostQuery } from '../../store/endpoints/posts'
import { useUserQuery } from '../../store/endpoints/root'
import PostNavigation from './components/PostNavigation/PostNavigation'
import {
  initializePostsFormByPost as initializePostFormByPost,
  initializePostsFormByUser as initializePostFormByUser,
} from './components/PostsFormContainer/components/PostsForm/PostsForm.utils'
import PostsFormContainer from './components/PostsFormContainer/PostsFormContainer'
import { PostStatusProvider } from './hooks/usePostStatus'
import { getPostStatus } from './PostPage.utils'

const PostPage: FC = () => {
  const { id = 'new' } = useParams<{ id: string }>()

  const isNewPost = id === 'new'
  const { data: post, isLoading: isPostLoading } = usePostQuery(id, { skip: isNewPost })
  const { data: user, isLoading: isUserLoading } = useUserQuery()
  const { socialAccounts, isLoading: isSocialAccountsLoading } = useSocialAccounts()

  const isShowSkeleton = isPostLoading || isUserLoading || isSocialAccountsLoading

  const initializedPostFormValues = useMemo(() => {
    if (isNewPost) {
      if (user !== undefined) return initializePostFormByUser(user)
    }

    if (post !== undefined) return initializePostFormByPost(post, id)

    return undefined
  }, [isNewPost, user, post, id])

  return (
    <PostStatusProvider value={getPostStatus(post, id)}>
      <RootBox>
        <PostNavigation postId={id} isScheduledPost={!!post?.scheduledTime} />
        {isShowSkeleton ? (
          <PostsFormSkeleton />
        ) : (
          <PostsFormContainer
            initPostFormValues={initializedPostFormValues}
            socialAccounts={socialAccounts ?? []}
          />
        )}
      </RootBox>
    </PostStatusProvider>
  )
}

export default PostPage
