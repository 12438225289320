import { type NotEmptySocialAccount } from '../../../../hooks/useSocialAccounts'
import { type MediaValue } from '../../../../types'
import { type PostFormValues } from './components/PostsForm/components/PostForm/PostForm.types'

export interface SubmitOptions {
  isDraft?: boolean
  scheduledTime?: string
  submitType: PostSubmitType
}

export interface PostsFormContainerProps {
  socialAccounts: NotEmptySocialAccount[]
  initPostFormValues?: Partial<PostFormValues>
}

export enum PostSubmitType {
  Draft = 'draft',
  Schedule = 'schedule',
  Instant = 'instant',
}

export interface BackMediaValue extends MediaValue {
  id: string
}
