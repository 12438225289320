import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import Card from '@mui/joy/Card'
import Modal from '@mui/joy/Modal'
import ModalClose from '@mui/joy/ModalClose'
import Typography from '@mui/joy/Typography'
import { type FC, useState } from 'react'

import HorizontalCardSkeleton from '../../../../components/skeletons/HorizontalCardSkeleton/HorizontalCardSkeleton'
import {
  useCancelMutation,
  usePlansQuery,
  useStatusQuery,
} from '../../../../store/endpoints/payment'
import { useUserQuery } from '../../../../store/endpoints/root'
import { type SubscriptionPlanResponse } from '../../../../types'
import PaymentPlans from './components/PaymentPlans/PaymentPlans'
import { type PlanId } from './components/PaymentPlans/PaymentPlans.type'
import PlanCard from './components/PlanCard/PlanCard'
import StripeAdapter from './components/StripeAdapter/StripeAdapter'
import SubscriptionAlert from './components/SubscriptionAlert/SubscriptionAlert'
import { getPlans } from './PaymentPlansContainer.utils'

const PaymentPlansContainer: FC = () => {
  const { data: status } = useStatusQuery()
  const { data: user, isLoading: isUserLoading } = useUserQuery()
  const { data: plansAndGroup } = usePlansQuery()
  const [cancelSubscription, { isLoading: isCancelLoading }] = useCancelMutation()

  const plans = getPlans(plansAndGroup?.[0], user?.subscription)
  const activePlan =
    plans?.find((plan) => plan.isActive) ??
    plans?.find((plan) => plan.id === user?.subscription?.planId)

  const [isOpenPlansModal, setIsOpenPlansModal] = useState(false)
  const [upgradePlaneId, setUpgradePlaneId] = useState<PlanId | null>(null)

  const handleManageClick = (): void => {
    setIsOpenPlansModal(true)
  }

  const handleCloseClick = (): void => {
    setUpgradePlaneId(null)
    setIsOpenPlansModal(false)
  }

  const handlePlanUpgradeClick = (plan: SubscriptionPlanResponse): void => {
    if (plan?.id === undefined) {
      throw new Error(`Cannot upgrade to undefined`)
    }

    setUpgradePlaneId(plan.id)
  }

  const handleCancelClick = async (plan: SubscriptionPlanResponse): Promise<void> => {
    if (plan?.id === undefined) {
      throw new Error(`Cannot cancel of undefined`)
    }

    await cancelSubscription({ planId: plan.id })
    setIsOpenPlansModal(false)
  }

  return (
    <>
      {status?.active === false && <SubscriptionAlert status={status} />}

      {isUserLoading && <HorizontalCardSkeleton />}

      {activePlan !== undefined && (
        <PlanCard
          plan={activePlan}
          actions={
            <Button variant="outlined" onClick={handleManageClick}>
              Manage
            </Button>
          }
        />
      )}

      {plans !== undefined && (
        <Modal
          open={isOpenPlansModal}
          onClose={handleCloseClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Card
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              padding: 0,
            }}
          >
            <ModalClose />
            {upgradePlaneId === null ? (
              <Box sx={{ p: 3 }}>
                <Typography level="title-md" sx={{ textAlign: 'left' }}>
                  Manage plan
                </Typography>
                <Typography level="body-sm" sx={{ textAlign: 'left', mt: 1 }}>
                  You can change or cancel your plan at any time
                </Typography>
                <PaymentPlans
                  sx={{ gap: 3, mt: 3 }}
                  status={status?.status}
                  plans={plans}
                  loading={isCancelLoading}
                  onPlanUpgrade={handlePlanUpgradeClick}
                  onPlanCancel={handleCancelClick}
                />
              </Box>
            ) : (
              <StripeAdapter key={upgradePlaneId} planId={upgradePlaneId} />
            )}
          </Card>
        </Modal>
      )}
    </>
  )
}

export default PaymentPlansContainer
