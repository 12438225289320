import Button from '@mui/joy/Button'
import { type FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { TbCalendarEvent } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import Bar from '../../../../../../ui/Bar/Bar'
import { usePostStatus } from '../../../../hooks/usePostStatus'
import { PostSubmitType } from '../../PostsFormContainer.types'
import { type PostsFormBarProps } from './PostsFormBar.types'

const PostsFormBar: FC<PostsFormBarProps> = ({ isSubmitting, ...props }) => {
  const navigate = useNavigate()

  const handleCancel = (): void => {
    navigate(-1)
  }

  const status = usePostStatus()
  const {
    formState: { isValid, isValidating },
  } = useFormContext()

  return (
    <Bar key="posts-form-bar" {...props}>
      <Button variant="outlined" onClick={handleCancel}>
        Cancel
      </Button>
      <Button
        sx={{ ml: 'auto' }}
        type="submit"
        name={PostSubmitType.Draft}
        loading={isSubmitting}
        variant="outlined"
      >
        {status === 'DRAFT' ? 'Update draft' : 'Save as Draft'}
      </Button>
      <Button
        type="submit"
        name={PostSubmitType.Instant}
        loading={isSubmitting}
        disabled={!isValid || isValidating}
      >
        Publish now
      </Button>
      <Button
        disabled={!isValid || isValidating}
        loading={isSubmitting}
        type="submit"
        name={PostSubmitType.Schedule}
        startDecorator={<TbCalendarEvent />}
      >
        {status === 'SCHEDULED' ? 'Reschedule' : 'Schedule'}
      </Button>
    </Bar>
  )
}

export default PostsFormBar
