import { createSlice, type Draft } from '@reduxjs/toolkit'

import postsApi from '../endpoints/posts'
import { trackId, untrackId } from './actions'
import { selectReducer, unselectReducer } from './reducers'
import { type PostsSlice } from './types'

const initialState: PostsSlice = {
  selectedIds: [],
  trackedIds: [],
}

export const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    select: selectReducer,
    unselect: unselectReducer,
  },
  extraReducers: (builder) =>
    builder
      .addCase(trackId, (state: Draft<PostsSlice>, action) => {
        state.trackedIds.push(action.payload)
      })
      .addCase(untrackId, (state: Draft<PostsSlice>, action) => {
        state.trackedIds = state.trackedIds.filter((id) => id !== action.payload)
      })
      .addMatcher(
        postsApi.endpoints.removePosts.matchFulfilled,
        (state: Draft<PostsSlice>, action) => {
          state.selectedIds = state.selectedIds.filter((id) => id !== undefined)
        },
      ),
})

export default postsSlice.reducer

export const { select, unselect } = postsSlice.actions
