import { useCallback } from 'react'

import { useLazyGetTextPreviewQuery } from '../../../../store/endpoints/postsTemplates'
import { type ProductByIdResponse } from '../../../../types'
import { isRichText } from './PostFormContainer.utils'

type TextEnricher = (text: string, product?: ProductByIdResponse) => Promise<string>

export const useEnrichText = (): { enrichText: TextEnricher } => {
  const [getTextPreview] = useLazyGetTextPreviewQuery()

  const enrichText = useCallback(
    async (text: string, product?: ProductByIdResponse): Promise<string> => {
      if (isRichText(text) && product) {
        const { data } = await getTextPreview({ template: text, product })

        return data?.description ? data.description : text
      }

      return text
    },
    [getTextPreview],
  )

  return { enrichText }
}
