import type { FC } from 'react'
import MuiAvatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import type { Variant } from '@mui/material/styles/createTypography'

export interface AvatarProps {
  src: string
  name: string
  color?: string
  nameVariant?: Variant
  secondaryText?: string
  size?: number
  inline?: boolean
}

const Avatar: FC<AvatarProps> = ({
  src,
  name,
  secondaryText,
  color,
  nameVariant = 'subtitle2',
  size = 34,
  inline = false
}) => {
  const secondaryTextElement = <Typography variant='caption' sx={{ mixBlendMode: 'luminosity', fontWeight: 300 }}>{secondaryText}</Typography>

  return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: (theme) => theme.spacing(1.375) }}>
            <MuiAvatar
                src={src}
                alt={name}
                sx={{ width: size, height: size }}
            />
            <Box>
                <Typography variant={nameVariant} sx={{ mixBlendMode: 'luminosity' }}>{name}</Typography>
                {(secondaryText !== undefined) && !inline && secondaryTextElement}
            </Box>
            {(secondaryText !== undefined) && inline && secondaryTextElement}
        </Box>
  )
}

export default Avatar
