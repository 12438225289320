import { type BoxProps } from '@mui/joy/Box'
import IconButton from '@mui/joy/IconButton'
import Typography from '@mui/joy/Typography'
import { type FC } from 'react'
import { TbArrowBackUp } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import { Bar } from '../../../../components/views'

const PostNavigation: FC<{ postId: string; isScheduledPost?: boolean } & BoxProps> = ({
  postId,
  isScheduledPost = false,
  children,
  ...props
}) => {
  const navigate = useNavigate()

  const handleBackClick = (): void => {
    navigate(-1)
  }

  return (
    <Bar sx={{ gap: 2 }} {...props}>
      <IconButton
        sx={{ color: 'text.icon' }}
        size="md"
        variant="outlined"
        onClick={handleBackClick}
      >
        <TbArrowBackUp />
      </IconButton>
      <Typography sx={{ fontSize: 'xl' }} level="title-lg">
        {postId === 'new' ? 'New post' : isScheduledPost ? 'Scheduled post' : 'Draft post'}
      </Typography>
      {children}
    </Bar>
  )
}

export default PostNavigation
