import { type ReactElement, useCallback, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import usePublishingPosts from '../../hooks/usePublishingPosts'
import useTrackedPosts from '../../hooks/useTrackedPosts'
import { useAppDispatch } from '../../store'
import { usePostsQuery, useRemovePostsMutation } from '../../store/endpoints/posts'
import { pushSuccessNotification } from '../../store/notifications/actions'
import { type OpenPostStatus, type PostType } from '../../types'
import Pagination from '../../ui/Pagination/Pagination'
import TablePage from '../../ui/TablePage/TablePage'
import BaseContextBar from './components/base/BaseContextBar'
import BaseHeader from './components/base/BaseHeader'
import BasePostsTable from './components/base/BasePostsTable'
import BaseSkeletonHeader from './components/base/BaseSkeletonHeader'
import BaseSkeletonRow from './components/base/BaseSkeletonRow'
import PostsTableWithNavigation from './components/base/PostsTableWithNavigation'
import DraftHeader from './components/draft/DraftHeader'
import DraftRow from './components/draft/DraftRow'
import DraftSkeletonHeader from './components/draft/DraftSkeletonHeader'
import DraftSkeletonRow from './components/draft/DraftSkeletonRow'
import EmptyPosts from './components/EmptyPosts/EmptyPosts'
import FailedRow from './components/failed/FailedRow'
import PostsNotFound from './components/PostNotFound/PostsNotFound'
import PostsBar from './components/PostsBar/PostsBar'
import PostsStatusContainer from './components/PostStatusContainer/PostsStatusContainer'
import ScheduledRow from './components/scheduled/ScheduledRow'
import SentHeader from './components/sent/SentHeader'
import SentPostsTable from './components/sent/SentPostsTable'
// import SentRow from './components/sent/SentRow'
import SentRowOrSkeleton from './components/sent/SentRowOrSkeleton'
import { type PostTableComponentsByStatus } from './types'
import { normalizePostsFilterParams } from './utils'

const SKELETON_COUNT = 7
const SKELETON_POSTS = [...Array(SKELETON_COUNT)].map((_, index) => ({ id: index.toString() }))
const COMPONENTS: PostTableComponentsByStatus = {
  DRAFT: {
    Table: PostsTableWithNavigation,
    Header: DraftHeader,
    Row: DraftRow,
    SkeletonHeader: DraftSkeletonHeader,
    SkeletonRow: DraftSkeletonRow,
    ContextBar: BaseContextBar,
  },
  SENT: {
    Table: SentPostsTable,
    Header: SentHeader,
    Row: SentRowOrSkeleton,
    SkeletonHeader: BaseSkeletonHeader,
    SkeletonRow: BaseSkeletonRow,
    ContextBar: BaseContextBar,
  },
  SCHEDULED: {
    Table: PostsTableWithNavigation,
    Header: BaseHeader,
    Row: ScheduledRow,
    SkeletonHeader: BaseSkeletonHeader,
    SkeletonRow: BaseSkeletonRow,
    ContextBar: BaseContextBar,
  },
  FAILED: {
    Table: BasePostsTable,
    Header: BaseHeader,
    Row: FailedRow,
    SkeletonHeader: BaseSkeletonHeader,
    SkeletonRow: BaseSkeletonRow,
    ContextBar: BaseContextBar,
  },
}

const PostsPage = (): ReactElement => {
  const publishingPosts = usePublishingPosts()
  useTrackedPosts()

  const [searchParams, setSearchParams] = useSearchParams()

  // move to normalizer
  const postType = searchParams.get('type')
  const socialAccountId = searchParams.get('social')
  const status = (searchParams.get('status') ?? 'DRAFT') as OpenPostStatus
  const type = searchParams.get('type') ?? ''
  const sorts = searchParams.getAll('sort') ?? ''
  const page = searchParams.get('page') ?? 1
  const size = searchParams.get('size')
  const search = searchParams.get('search')
  const hasFilter = searchParams.has('search')

  const requestSearchParams = normalizePostsFilterParams({
    status,
    page: +page - 1,
    size,
    postType: type,
    sort: sorts,
    search,
    accountId: socialAccountId,
  })

  const {
    data: pagePost,
    isFetching: isPostsFetching,
    isLoading: isPostsLoading,
  } = usePostsQuery(requestSearchParams.toString())

  console.dir(pagePost)

  const [removePosts, { isLoading: isRemovingPost }] = useRemovePostsMutation()

  const posts = [...publishingPosts, ...(pagePost?.content ?? [])]

  const navigate = useNavigate()

  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const dispatch = useAppDispatch()

  const handlePageChange = useCallback(
    (newPage: number): void => {
      setSearchParams((prevSearchParams) => {
        newPage === 1 ? prevSearchParams.delete('page') : prevSearchParams.set('page', `${newPage}`)
        return prevSearchParams
      })
    },
    [setSearchParams],
  )

  const handlePostTypeChange = useCallback(
    (value: string | null): void => {
      setSearchParams((prevSearchParams) => {
        value === null ? prevSearchParams.delete('type') : prevSearchParams.set('type', value)
        return prevSearchParams
      })
    },
    [setSearchParams],
  )

  const handleSocialAccountChange = useCallback(
    (value: string | null): void => {
      setSearchParams((prevSearchParams) => {
        value === null ? prevSearchParams.delete('social') : prevSearchParams.set('social', value)
        return prevSearchParams
      })
    },
    [setSearchParams],
  )

  const handleSearchChange = useCallback(
    (value: string | null): void => {
      setSearchParams((prevSearchParams) => {
        prevSearchParams.delete('page')
        value === null ? prevSearchParams.delete('search') : prevSearchParams.set('search', value)
        return prevSearchParams
      })
    },
    [setSearchParams],
  )

  const handleSortsChange = useCallback(
    (newSorts: string[] | null): void => {
      setSearchParams((prevSearchParams) => {
        prevSearchParams.delete('sort')
        newSorts?.forEach((newSort) => {
          prevSearchParams.append('sort', newSort)
        })
        return prevSearchParams
      })
    },
    [setSearchParams],
  )

  const handleNewPostClick = useCallback((): void => {
    navigate('new')
  }, [navigate])

  const handleRemoveClick = useCallback(async (): Promise<void> => {
    await removePosts(selectedIds)
    dispatch(pushSuccessNotification({ message: 'The post is successfully deleted' }))
    setSelectedIds([])
  }, [selectedIds, removePosts, dispatch])

  const isPostsFetchingOrLoading = isPostsFetching || isPostsLoading

  const Components = COMPONENTS[status]

  return (
    <TablePage
      navigation={<PostsStatusContainer activeStatus={status} />}
      bar={
        Components.ContextBar !== undefined && selectedIds.length !== 0 ? (
          <Components.ContextBar
            isRemoving={isRemovingPost}
            selectedAmount={selectedIds.length}
            onRemoveClick={handleRemoveClick}
          />
        ) : (
          <PostsBar
            postType={postType as PostType | null}
            socialAccountId={socialAccountId}
            search={search}
            onNewPostClick={handleNewPostClick}
            onPostTypeChange={handlePostTypeChange}
            onSocialAccountChange={handleSocialAccountChange}
            onSearchChange={handleSearchChange}
          />
        )
      }
    >
      {!isPostsFetchingOrLoading && posts.length !== 0 && (
        <Components.Table
          posts={posts}
          header={Components.Header}
          row={Components.Row}
          sorts={sorts}
          onSortsChange={handleSortsChange}
          selected={selectedIds}
          onSelectedChange={setSelectedIds}
        />
      )}
      {isPostsFetchingOrLoading && (
        <Components.Table
          isHideCheckboxes
          posts={SKELETON_POSTS}
          header={Components.SkeletonHeader}
          row={Components.SkeletonRow}
        />
      )}
      {!isPostsFetchingOrLoading && posts.length === 0 && !hasFilter && <EmptyPosts />}
      {!isPostsFetchingOrLoading && posts.length === 0 && hasFilter && <PostsNotFound />}
      {pagePost?.totalPages !== undefined && pagePost?.totalPages > 1 && (
        <Pagination count={pagePost?.totalPages} page={+page} onPageChange={handlePageChange} />
      )}
    </TablePage>
  )
}

export default PostsPage
