import { type BaseSyntheticEvent } from 'react'

import { type PostFormValues } from './components/PostsForm/components/PostForm/PostForm.types'
import { type BackMediaValue, PostSubmitType } from './PostsFormContainer.types'

export const getSubmitType = (event?: BaseSyntheticEvent): PostSubmitType => {
  const submitterName = ((event?.nativeEvent as SubmitEvent)?.submitter as HTMLButtonElement)?.name

  switch (submitterName) {
    case PostSubmitType.Schedule:
    case PostSubmitType.Draft:
    case PostSubmitType.Instant:
      return submitterName
    default:
      throw new Error(`Unknown SubmitterName: ${submitterName}`)
  }
}

export const isRichText = (text: string): boolean => text.includes('{') && text.includes('}')

export const getPostStatusAfterSubmit = (submitType: PostSubmitType): string => {
  switch (submitType) {
    case PostSubmitType.Draft:
      return 'DRAFT'
    case PostSubmitType.Schedule:
      return 'SCHEDULED'
    case PostSubmitType.Instant:
      return 'SENT'
  }
}

export const createPostBody = (
  formValues: PostFormValues,
  richCaption: string,
  scheduledTime?: string,
): any => {
  return {
    id: formValues.postId,
    sourceAccount: {
      id: formValues.sourceAccountId,
      platform: 'SHOPIFY',
    },
    socialAccount: {
      id: formValues.socialAccountId,
      platform: formValues.socialName,
    },
    platformData: {
      postType: formValues.postType,
      caption: richCaption,
    },
    media: formValues.media.filter((media): media is BackMediaValue => media.id !== undefined),
    scheduledTime,
    product: {
      id: formValues.productId,
    },
  }
}
